import { Link, Typography } from "@mui/material";
import { Link as RouterLink } from "react-router-dom";
import Translate from "src/components/translate";
import useResponsive from "src/hooks/useResponsive";
import LanguagePopover from "src/layouts/shared/header/language-popover";
import { PATH_AUTH } from "src/routes/paths";

const GetStartedTitle = () => {
  const smUp = useResponsive("up", "sm");

  return (
    smUp && (
      <Typography variant="body2" sx={{ mt: { md: -2 } }}>
        <LanguagePopover />
        
      </Typography>
    )
  );
};

export default GetStartedTitle;
